<template>
  <Contact
    :response="`${$t('responseInvitationMessage')}`"
    propMessage="Invitation request"
    title="requestInvite"
  >
    <div class="text-center">
      <h1>Interested?</h1>
      <p></p>
      <h2>{{ $t('requestAccess0') }}</h2>
      <h3>{{ $t('requestAccess1') }}</h3>
      <h3>{{ $t('requestAccess2') }}</h3>
    </div>
  </Contact>
</template>

<script>
import Contact from '@/views/Notify';
export default {
  name: 'InvitationReqiest',
  components: { Contact },
};
</script>
