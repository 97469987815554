<template>
  <static-template>
    <div class="contact-wrapper">
      <slot></slot>
      <div class="neo-card mt-6">
        <h1 v-if="!sent">{{ $t(title) }}</h1>

        <template v-if="!sent">
          <p>
          </p>
          <v-form ref="contactForm" v-model="valid" lazy-validation>
            <div class="neo-text-field">
              <div>{{ $t('name') }}</div>
              <v-text-field
                v-model="name"
                flat
                solo
                outlined
                dense
                :label="$t('name')"
                required
                :rules="[rules.required]"
              ></v-text-field>
            </div>
            <div class="neo-text-field">
              <div>{{ $t('email') }}</div>
              <v-text-field
                v-model="email"
                flat
                solo
                outlined
                dense
                :label="$t('email')"
                required
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </div>
            <div class="text-center mt-4">
              <v-btn
                large
                :disabled="!valid || disabled"
                color="primary"
                id="send-message"
                @click="validate"
              >
                <v-icon class="mr-2">mdi-send</v-icon>
                {{ $t('send') }}
              </v-btn>
              <VueRecaptcha
                :sitekey="this.key"
                @verify="onVerify"
                @expired="onExpired"
                ref="recaptcha"
                size="invisible"
                badge="inline"
                type="contact"
                :loadRecaptchaScript="false"
                theme="light"
              ></VueRecaptcha>
            </div>
          </v-form>
          <p class="reCAPTCHA-disclaimer text-center">
            This site is protected by reCAPTCHA and the Google
            <a target="_blank" href="https://policies.google.com/privacy"
              >Privacy Policy</a
            >
            and
            <a target="_blank" href="https://policies.google.com/terms"
              >Terms of Service</a
            >
            apply.
          </p>
        </template>
        <CommonSuccessCheckout
          v-else
          class="mt-6 mb-4"
          :text="response"
        />
      </div>
    </div>
  </static-template>
</template>

<script>
import CommonSuccessCheckout from '@/components/Organisms/CommonSuccessCheckout';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'ContactPage',
  props: {
    propMessage: {type: String, default: null},
    response: {type: String, default: null},
    title: {type: String, default: 'contactUs'}
  },
  components: {
    CommonSuccessCheckout,
    VueRecaptcha,
  },
  data: () => ({
    recaptchaOk: false,
    key: process.env.VUE_APP_RECAPTCHA_SITE,
    valid: false,
    sent: false,
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    disabled: false,
    fieldRules: [(v) => !!v || 'Required'],
    emailRules: [
      (v) => !!v || 'Required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => (v && v.length >= 9) || 'Min 9 characters',
      max: (v) => (v && v.length <= 250) || 'Max 150 characters',
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || 'E-mail must be valid',
      number: (v) => Number.isInteger(Number(v)) || 'Invalid telephone number',
    },
  }),
  methods: {
    errorMethod(e) {
      console.log('errorMethod ', e);
    },
    expiredMethod(e) {
      console.log('expiredMethod ', e);
    },
    validate() {
      if (this.$refs.contactForm.validate()) this.sendMessage();
    },
    onVerify(response) {
      if (response !== '') {
        this.sendMail();
      } else {
        return false;
      }
    },
    onExpired() {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.disabled = true;
    },
    sendMessage() {
      this.$refs.recaptcha.execute();
    },
    sendMail() {
      const payload = {
        name: this.name,
        email: this.email,
        message: this.propMessage,
      };
      fetch(process.env.VUE_APP_EMAIL_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        // .then(() => {
        //   this.sent = true;
        //   this.resetForm();
        // })
        .catch(() => {
          this.error = true;
        });
      this.sent = true;
      this.resetForm();
    },
    resetForm() {
      this.contactFormData = {
        name: '',
        email: '',
        message: '',
        phoneNumber: '',
      };
    },
  },
};
</script>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
  position: absolute;
}
</style>

<style lang="scss" scoped>
.reCAPTCHA-disclaimer {
  font-size: var(--font-size-super-small);
}
.contact-wrapper {
  margin: auto;
  @media only screen and (min-width: 1024px) {
    max-width: 80%;
  }
}
</style>
